.form-bg{
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-card{
    padding: 20px 50px;
    border-radius:6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.login-form-title{
    font-size: 20px;
    font-weight:600;
    text-transform: capitalize;
    // color:$theme-orange-color;
    line-height: 1.2;
}

.log-in-title{
    font-size: 28px;
    font-weight:400;
    line-height: 1.2;
    color:$primary-color;
}

// OVERRIDE ANT DESIGN LABEL
.ant-form-item-label{
    label{
        color:$theme-dark-color!important;
        font-size:$label-font-size!important;
        font-weight: 500;
        letter-spacing: .10px;
        text-transform: capitalize;
    }
}
.form-btn{
    padding:5px 10px!important;
    // height:40px!important;
    background:$primary-color!important;
    color: white !important;
    font-size:16px!important;
    font-weight:400!important;
    min-width:100px;
    // text-transform: capitalize;
    border:none;
    border-radius:4px;
}