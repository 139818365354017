$side-bg:#3c4b64;

$side-bg-secondary:#303c54;

$primary-color:#F01B23; // LOGO RED COLOR

$secondary-color:#161A64; // LOGO BLUE COLOR

$font-color-light:hsla(0,0%,100%,.8);

$font-light-secondary:#dcdde1;

$font-secondary:#8f7f7f99;

$label-font-size : 17px;

$theme-dark-color : #1D1A11 ;

$theme-red-color : #6B0512 ;

$theme-orange-color : #FF6600 ;

$body-bg-color:#ebedef;

$bg-light : #F9F9FF

