@media screen and (max-width: 992px) {

  .responsive-top-menu-social-media{
    display: block!important;
    div{
      text-align: center!important;
    }
  
  }
  .responsive-nav {
    display: block;
  }

  .main-navigation {
    display: none;
  }

  .contact-leftSection-img {
    text-align: center;
    transform: translateX(0px);
  }

  .contactUsMap {
    text-align: center;
  }

  // .history-content-wrapper,.home-about-content{
  //   transform: translateX(0px);
  // }
  .contact-form-wrapper {
    padding: 20px;
  }

  .web-footer {
    background-size: cover;
    background-position: top;
  }

  .testimonial-slider {
    max-width: 100%;
  }
}


@media screen and (max-width: 768px) {
  
  .responsive-nav {
    display: block;
  }
 
  .mb-n5{
    div{
      p{
        width: auto;
      }
    }
  }

  .main-navigation {
    display: none;
  }

  .text-sm-justify {
    text-align: justify !important;
  }

  .product-dropdown {
    max-width: 80vw;
    left: -62px;
  }

  .home-about-content {
    padding: 20px 20px;

    p {
      font-size: 18px;
    }
  }

  .font-sm-18 {
    font-size: 18px;
  }

}


@media screen and (max-width: 1400){
  .navbar-menu{
    ul{
      li : { margin: 20px 0px 20px 30px}
    }
  }
}
@media screen and (min-width: 1326px){
  .navigation-menu-container{
    display: flex;
  }
}