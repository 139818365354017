.layout-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrapper{
    flex: 1;
}
.fs-30{
    font-size: 30px;
}
.fs-18{
    font-size: 18px;
}
// ======================================================== SHIMMER ========================================================================

.shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    // background-size: 800px 104px;
    display: inline-block;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.slick-dots li{
    margin:0px;
    width:15px;
    height:15px;
}
.bottom-wa-icon{
    z-index: 1111;
    // background :#43c654;
    background :$primary-color;
    position: fixed;
    bottom: 15px;
    left: 15px;
    border-radius: 10px;
    height: 55px;
    // width: 60px;
    padding: 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: flashbtn 6s infinite;
    a{
        // color: #43c654!important;
        color :#FFFFFF!important;
        font-weight: 500;
        font-size: 18px;
        border:none;
        background: none;
        svg{
            font-size: 32px;
        }
    }
}
@keyframes flashbtn{
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0%);
    }
    40% {
        transform: translateY(-100%);
    }
    60% {
        transform: translateY(-100%);
    }
}


@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.enquiryPopup{
    padding : 20px;
    position: fixed;
    bottom: -280px;
    right:20px;
    // height: 50px;
    width: 500px;
    border-radius: 8px;
    background: white;
    z-index: 1111;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: .5s;
    .top-close-btn{
        position: absolute;
        right :-10px;
        top:-10px;
        button{
            display: flex;
            padding: 0px;
            border:none;
            font-size:24px;
            color:black; 
            border-radius: 10px;
            padding:8px;
            // background: $primary-color;
        }
    }
    .send-btn{
        padding : 5px 20px;
        background: #2a2f4d;
        font-size: 20px;
        // font-weight: 500;
        text-transform: capitalize;
        color : #FFFFFF;
    }
    .close-btn{
        padding : 5px 20px;
        border: 2px solid #2a2f4d;
        font-size: 20px;
        // font-weight: 500;
        text-transform: capitalize;
        color : #2a2f4d;
    }
}
.open-equiry{
    bottom: 15px;
    right:20px; 
}

// ========================================================== COMMON SYTLE ==================================================================
.background-light {
    background-color: $bg-light;
}

.background-primary {
    background-color: $primary-color;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}


.font-30 {
    font-size: 30px;
}

.light-font-color {
    color: $font-light-secondary;
}

.cursor-pointer {
    cursor: pointer;
}

// .nav-shadow{
//     box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
//     // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
// }

.mb-n5 {
    margin-bottom: -5px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 32px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    div {
        width: 100vw;
        margin: auto;
        display: flex;

        p {
            width: 33%;
            font-size: clamp(1.2rem, 1.7vw, 2.3rem);
            text-align: center;
        }

        // justify-content: space-evenly;
        // -webkit-justify-content: space-evenly;
        // -moz-justify-content: space-evenly;
    }
}



.text-justify {
    text-align: justify;
}

.line-height-20 {
    line-height: 23px;
}

.font-primary {
    color: $primary-color !important
}

.dark-79 {
    color: rgba(66, 66, 66, 0.79);
}

.dark-font {
    color: #424242
}

.font-secondary {
    color: $secondary-color;
}

.section-heading {
    color: #424242;
    font-size: 30px;
    // font-family: 'Constantia';
    font-weight: 600;
    line-height: 45px;

}

.section-left-border {
    padding-left: 2%;
    position: relative;
}

.section-left-border::before {
    content: "";
    position: absolute;
    height: 40px;
    width: 4px;
    border-radius: 5px;
    left: 10px;
    top: 6px;
    bottom: 0px;
    background: rgba(240, 27, 35, 0.55);
}

.z-index-top {
    z-index: 1;
}

.custom-card-shadow {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.light-custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.category-hover:hover {
    color: $primary-color
}

.home-product-card-slider {
    height: 330px;
    overflow: hidden;

    img {

        height: 200px;
        margin: auto;
    }
}

.border-radius-20 {
    border-radius: 20px
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.heading-font-family {
    font-family: 'Constantia';
}


// ========================================================== NAVIGATION SYTLE ===============================================================
.navigation {
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    // position: -webkit-sticky;
    position: sticky;
    width: 100%;
    top: 0px;
    height: auto;
    z-index: 99;
    // width:100vw;
}

.top-navigation-contact-details {
    position: relative;

}

.top-navigation-contact-details::after {
    position: absolute;
    content: "";
    background: white;
    height: 15px;
    width: 1px;
    right: 0px;
    bottom: 5px;

}

.navigation-top {
    background-color: $primary-color;
    height: auto;

    .nav-right-ul {
        li {
            padding: 0px 8px;
        }
    }
}

.main-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navlink-span {
    cursor: pointer;
}

.navbar-menu {
    height: auto;

    ul {
        li {
            display: block;
            margin: 10px;

            a,
            .navlink-span {
                display: inline-block;
                color: #424242;
                font-weight: bold;
                padding: 3px 10px;
                font-size: 16px;
                text-transform: uppercase;
                font-family: 'Constantia';
            }

            .active-nav-menu {
                position: relative;
                color: rgba(5, 5, 5, 0.7);
            }


            .active-nav-menu::after {
                content: "";
                height: 2px;
                width: 10px;
                position: absolute;
                top: -2px;
                background: $primary-color;
                left: calc(50% - 5px);
            }

            .top-nav-btn {
                font-size: 16px !important;
                text-transform: capitalize !important;
                text-align: center;
                background: $primary-color;
                // padding: 6px 22px;
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: 500;
                border: 1px solid $primary-color;
                transition: .8s;
            }

            .top-nav-btn:hover {
                color: $primary-color;
                background: #FFFFFF;
            }
        }

    }
}

.main-nav-hover {
    position: relative;
}

.main-nav-hover::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    content: '';
    color: transparent;
    background: $primary-color;
    transition: .6s;
    opacity: 0;
    visibility: none;
    z-index: -1;
}

.main-nav-hover:hover:before {
    opacity: 1;
    visibility: visible;
    height: 100%;
}

.main-nav-hover:hover,
.active-nav-menu:hover {
    color: #FFFFFF !important;
}

.productLink:hover {
    .product-dropdown {
        height: 400px;
        max-height: 400px;
        overflow-y: auto;
        max-width: 100vw;
        transition: height .5s;
    }

    /* width */
    .product-dropdown::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    .product-dropdown::-webkit-scrollbar-track {
        background: #e5dfdf;
        border-radius: 30px;
    }

    /* Handle */
    .product-dropdown::-webkit-scrollbar-thumb {
        background: #c7c1c1;
        border-radius: 30px;
    }

    /* Handle on hover */
    .product-dropdown::-webkit-scrollbar-thumb:hover {
        background: #c5bfbf;
    }
}

.product-dropdown {
    transition: height .5s;
    height: 0px;
    overflow: hidden;
    // display:none;
    box-shadow: 0px 0px 50px rgba(6, 6, 77, 0.05);
    background: #FFFFFF;
    border-radius: 6px;
    min-width: 260px;
    max-width: 230px;
    z-index: 111;

    li {
        margin: 0px !important;

        p {
            padding: 10px 20px !important;
            font-weight: 500 !important;
            // font-family: Arial, Helvetica, sans-serif!important;
            font-size: 14px !important;
            font-weight: 500 !important;
            text-transform: capitalize !important;
            margin-bottom: 0px;
        }
    }

    li:hover {
        p {
            color: $primary-color
        }

    }
}

// ======================================================== RESPONSIVE NAVIGATION STYLE =====================================================

.responsive-nav {
    display: none;
}

.nav-visible {

    max-height: auto;
    padding-bottom: 3px;
}

.nav-hidden {
    max-height: 0;
    overflow: hidden
}


// ========================================================== FOOTER SYTLE ==================================================================

.web-footer {
    // background-image: url('http://localhost:3008/images/footerbg.png');
    height: auto;
    background-size: 100% 100&;
    background-repeat: no-repeat;
    position: relative;

}
.footer-bg-img{
    position: absolute;
    top:0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    img{
        height: 100%;
        width: 100%;
    }

}
.footer-overlap {
    height: 100%;
    width: 100%;
    z-index :1111;
    background-color: rgba(0, 0, 0, .3);
}

.footer-social-icons {
    display: flex;
    align-items: center;

    li {
        display: block;
        margin: 0px 8px 0px 0px;
        height: 30px;
        width: 30px;
        transition: .5s;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        // padding:5px;
        a {
            height: 100%;
            width: 100%;
            text-align: center;
        }
    }

    li:hover {

        background: red;

    }
}

.footer-quick-links {
    li>a:hover {
        border-bottom: 1px solid white;
    }
}

.footer-product-hover {
    a:hover {
        border-bottom: 1px solid white;
    }
}

// ====================================================== CONTACT US SYTLE=================================================================

.contact-header-title {
    background-image: url('https://oliverllp.com/images/contactusHeader.png');
    background-size: cover;
    padding: 8vh;
}

.contact-form-wrapper {
    padding: 50px 60px;
}
.enquiry-form-wrapper{
    padding : 0px 10px;
    .antd-input-custom-style {
        border: 1px solid rgba(0, 0, 0, .22) !important;
        height: 40px;
    }
}

.antd-input-custom-style {
    border: none !important;
    height: 40px;
}

.contact-form-btn {
    display: block !important;
    background: $primary-color !important;
    width: 100%;
    border: none !important;
    color: white !important;
    font-weight: 500 !important;
    box-shadow: none !important;

}

.contact-form-btn {
    height: auto !important;
    padding: 11px 15px !important;
}

// .contact-leftSection-img {
//     transform: translateX(125px);
// }

.contactUsMap>iframe {
    max-width: 100%;
    height: 400px;
}

// ======================================================== PRODUCT DETAILS PAGE STYLE ========================================================

// .product-heading-wrapper {
//     max-width: 60%;
// }

// ======================================================== PRODUCT PAGE STYLE ================================================================

.product-header-img {
    min-height: 200px;
}

.product-header-img>img {
    max-height: 200px;
    // position: absolute;
    // right: 0;
    // bottom: 0px;
}

// ======================================================= ABOUT US PAGE STYLE ================================================================
.overlap-about-section-counts {
    height: 100px;
    width: 200px;
    background: rgba(240, 27, 35, .7);
    // mix-blend-mode: darken;
    border-radius: 6px;

    p {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        transform: translateY(50%);
        color: #FFFFFF;
    }

    p>span {
        font-size: 16px;
        font-weight: 500;
    }
}

.overlap-about-section-counts {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

.why-us-card {
    padding: 20px 30px;

    .why-us-card-icon {
        background: #DA2128;
        height: 80px;
        width: 80px;
        margin: auto;
        position: relative;
        border-radius: 50%;

        span {
            color: #FFFFFF;
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.about-map {
    .rsm-marker {
        transition: .8s;
        opacity: 0;
    }

    .overlap-about-section-counts {
        transition: .8s;
        opacity: 1;
    }

}

.about-map:hover {
    .rsm-marker {

        transition: .8s;
        opacity: 1;
    }

    .overlap-about-section-counts {
        transition: .8s;
        opacity: 0;
    }
}

// HISTORY SECTION
.history-content-wrapper {
    box-sizing: border-box;
    // padding: 30px 30px;
    // transform: translateX(-100px);
    background: rgba(251, 251, 255, 0.8);
    border-radius: 4px;
}

// ======================================================================= HOME PAGE STYLE ===================================================
.home-header-bg {
    height: auto;
    // background-image: url('http://localhost:3008/images/header_bg.png');

    position: relative;

    img {
        // min-height: 150px;
        // max-height: 50vh;
        width: 100%;
        object-fit: contain;
    }

    // .home-header-overlap {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     top: 0px;
    //     bottom: 0px;
    //     right: 0px;
    //     left: 0px;
    //     background-color: rgba(0, 0, 0, 0.50);
    // }
}

.home-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        font-family: "Avant Garde", Avantgarde, "Century Gothic", CenturyGothic, "AppleGothic", sans-serif;
        // font-size: 48px;
        font-size: clamp(2rem, 3vw, 5rem);
        transform: translateX(-100px);
        opacity: 0;
        transition: .8s;
        text-transform: uppercase;
        text-rendering: optimizeLegibility;
        // color: #131313;
        letter-spacing: .15em;
        color: #e0dfdc;
        letter-spacing: .1em;
        text-shadow:
            0 -1px 0 #fff,
            0 1px 0 #2e2e2e,
            0 2px 0 #2c2c2c,
            0 3px 0 #2a2a2a,
            0 4px 0 #282828,
            0 5px 0 #262626,
            0 6px 0 #242424,
            0 7px 0 #222,
            0 8px 0 #202020,
            0 9px 0 #1e1e1e,
            0 10px 0 #1c1c1c,
            0 11px 0 #1a1a1a,
            0 12px 0 #181818,
            0 13px 0 #161616,
            0 14px 0 #141414,
            0 15px 0 #121212,
            0 22px 30px rgba(0, 0, 0, 0.9);

    }

    .header-text-ani {
        opacity: 1;
        transform: translateX(0px);
    }

    div>a {
        display: inline-block;
        padding: 8px 25px;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 12px;
        outline: 2px solid #FFFFFF;
    }
}

.partner-logo-height {
    max-height: 50px
}

.home-section-heading {
    display: inline-block;
    color: #424242;
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
    position: relative;
    font-family: 'Constantia';
}

.home-section-heading:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100px;
    bottom: 0px;
    left: 0px;
    margin: auto;
    right: 0px;
    border-radius: 10px;
    background: linear-gradient(90deg, #DA2128 2.96%, #000DFF 102.96%);
    ;
}

// HISTORY SECTION
.home-about-content {
    box-sizing: border-box;
    padding: 40px 40px;
    // transform: translateX(100px);
    background: rgba(251, 251, 255, 0.8);
    border-radius: 4px;

    p {
        font-size: 24px;
    }
}
.get-in-touch-wrapper{
    position : relative;
    height: fit-content;
}
.get-in-touch-bg {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .22);
    z-index: -1;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    // background-image: url('http://localhost:3008/images/getintouch_bg.png');
    // height: auto;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // position: relative;
    // background-size: cover;
}


.getInTouch-btn>a {
    display: inline-block;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    background: $primary-color;
}

.service-card {
    padding: 10px 15px;
    border-radius: 4px;
    transition: .8s;
}

.service-card:hover {
    box-shadow: 0px 0px 50px rgba(6, 6, 77, 0.05);
}

.home-client-card {
    padding: 30px 30px 30px 90px
}

.client-card-info {
    display: flex;
    align-items: center;
    position: relative;
}

.client-card-top {
    position: absolute;
    right: -20px;
}



.product-details-main-img {
    height: 300px;

    // overflow: hidden;
    img {
        height: 100%;
        object-fit: contain;
    }
}

.multiple-img-product {
    height: 100px;
    justify-content: center;

    img {
        height: 100%;
        margin: auto;
    }
}



.test-slider {
    .slick-list {
        text-align: center;
    }

    .slick-next,
    .slick-prev {
        background-color: $secondary-color !important;
        color: white !important;
        padding: 10px;
        box-sizing: content-box;
        border-radius: 50%;
    }

    .slick-next {
        right: -50px;
    }

    .slick-prev {
        left: -50px;
    }
}

.address-container {
    max-height: 400px;
    overflow: auto;
}

/* width */
.address-container::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.address-container::-webkit-scrollbar-track {
    background: #686262;
    border-radius: 30px;
}

/* Handle */
.address-container::-webkit-scrollbar-thumb {
    background: #c7c1c1;
    border-radius: 30px;
}

/* Handle on hover */
.address-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.featuredVideoIcon {
    position: absolute;
    font-size: 50px;
    margin: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.featuredVideoWrapper {
    height:90%;
    width: 90%;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        // object-fit: cover;
    }
}

// ==================================================== BLOG STYLE =============================================

.blog-card {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;
    border-radius: 6px;

    .blog-xl-img {
        width: 100%;
        height: 400px;
    }

    .blog-card-content {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);

    }
}

.blog-grid-card {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;
    border-radius: 4px;

    img {
        width: 100%;
        height: 256px;
    }
}

.blog-grid-overlap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.blog-xxl-img,
.blog-image {
    border-radius: 4px;
    // max-height: 500px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.hr-100 {
    background: rgba(0, 0, 0, .22);
    height: 1px;
    margin: 10px 0;
    width: auto;
}

.blog-social-media-links {
    list-style: none;
    display: flex;
    margin: 0px;
    padding: 0px;
    justify-content: start;
    margin: 10px 0px;

    li {
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            margin-right: 10px;
            font-size: 18px;
            border-radius: 50%;
            border: 1px solid #d6e5ff;
            // a{
            //     color:white;
            // }

        }

    }

    li:first-child {
        span {
            background: #eaf2ff;
        }

        a {
            color: #337fff;
        }

    }

    li:nth-child(2) {
        span {
            background: #e7f0f8;
        }

        a {
            color: #1569bf;
        }

    }

    li:nth-child(3) {
        span {
            background: #e5f8ff;
        }

        a {
            color: #3cf;
        }

    }

    li:nth-child(4) {
        span {
            background: #f6e9ed;
        }

        a {
            color: #c53a68;
        }

    }
}

.no-blog-wrapper {
    height: 30vh;

    p {
        text-align: center;
        font-size: 24px;
        color: $primary-color;
        font-weight: 500;
    }
}

.blog-content-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}