@import 'var.scss';
@import 'forms.scss';
@import 'loader.scss';
@import 'website.scss';
@import 'responsive.scss';
@import 'pagenotfound.scss';

body {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    top: 0px !important;
}
.responsive-top-menu-social-media{
    display: flex;
    justify-content: center;
}
.skiptranslate>iframe {
    visibility: hidden !important;
}
.goog-te-gadget {
    img {
        display: none !important;
    }

    .goog-te-gadget-simple {
        background-color: #fff !important;
        border: 1px solid $primary-color !important;
        border-radius: 2px !important;
        padding: 2px;

        .VIpgJd-ZVi9od-xl07Ob-lTBxed {
            color: $font-secondary;
            font-size: 12px;

            span:nth-child(3) {
                border-left: none !important;
            }
        }

        .VIpgJd-ZVi9od-xl07Ob-OEVmcd {
            box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)!important;
        }

        .VIpgJd-ZVi9od-vH1Gmf {
            border: none !important;
        }

        .VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
            iframe {
                box-shadow: 0px 5px 10px rgba(0, 0, 0, .2) !important;
            }
        }
    }
}

@media screen and (min-width:680px) {
    .layout-col-1 {
        min-width: 30% !important;
        max-width: 30% !important;
    }

}

@media screen and (min-width:1200px) {
    .layout-col-1 {
        min-width: 16% !important;
        max-width: 16% !important;
    }

    .sidebar-close-button {
        visibility: hidden !important;
    }
}

.country-dropdown {

    .ant-select-dropdown {
        // position: fixed !important
        z-index: 111 !important;
    }

}

.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.form-banner-img {
    max-height: 150px;
}

.table-image {
    max-height: 50px;
}

.img-100 {
    max-height: 100px;
}

.ant-space-item {
    width: 100%;
}

// .ant-select-dropdown {
//     position: fixed !important
// }

.img-400 {
    max-height: 300px;
    height: 300px;
}

.img-50 {
    max-height: 50px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: black
}

.tab-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
        .tab-list-menu-item {
            color: $primary-color;
            border: 1px solid $primary-color;
            border-radius: 6px;
            margin: 5px 10px 5px 0px;
            font-weight: 500;
            display: inline-block;
            padding: 1px 5px;
            transition: .4s;
        }

        .active-tab-menu {
            background: $primary-color;
            color: #FFFFFF;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table>:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, .2);
}

.contact-address-map>iframe {
    max-height: 500px;
    width: 100%;
}

.full-screen-gradient {
    background-image: linear-gradient(to left top, #da2128, #ef4e6e, #f47ca9, #f0a8d6, #edd0f2, #decbeb,
            #d0c6e2, #c4c0d8, #9993bb, #70689d, #463f81, #161a64);
}

.ant-form-item-label label {
    font-size: 15px !important;
    color: rgba(0, 0, 0, .69) !important;
}

.primary-font {
    color: $primary-color
}

.bg-transparent {
    background-color: transparent;
}

.secondary-font {
    color: $secondary-color
}

.primary-bg {
    background-color: $primary-color !important
}

.secondary-bg {
    background-color: $secondary-color
}

.form-btn-style {
    background-color: $primary-color !important;
    color: #FFFFFF !important;
}

.ant-pagination {
    justify-content: center !important;
}

// @media screen and (min-width: 1200px) {
//     
// }

// @media only screen and (max-width: 992px) {
//     .layout-col-1{
//         // transform : translateX(-100%);
//     }
//     .layout-col-2{
//         // width:100%!important;
//     }
// }


.fw-500 {
    font-weight: 500 !important
}

.textcolor-secondary {
    color: rgba(44, 56, 74, .681)
}

.fs-sm {
    font-size: 13px !important;
}

.master-layout {
    display: flex;
}

// .layout-col-1 {
//     background: $side-bg;
//     min-width:300px;
//     // position:fixed;
//     // background: red;
//     // flex-grow: 1;
//     // height: 100%;
//     overflow-y: auto;
//     transition: all 1s;
//   }

.layout-col-1 {
    background: $side-bg !important;
    height: 100vh;
    transition: .5s;
    overflow: auto;
    position: fixed;
    // min-width:300px;
    min-width: 80%;
    max-width: 80%;
    // width:16%;
    // top:-10px;
    // width:100%;
    z-index: 1111;
}

.side-close {
    transform: translateX(-100%);
    transition: all .5s;
}

.sidebar-close-button {
    visibility: visible;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    // text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 5px;
    background: rgba(0, 0, 0, .3);

    svg {
        font-size: 16px;
        color: white;
    }
}

.layout-col-1::-webkit-scrollbar {
    width: 7px;
}

.layout-col-1::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 5px;
}

.layout-col-1::-webkit-scrollbar-thumb {
    background: #2a3d5a;
    border-radius: 5px;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

//   .layout-col-2 {
//     display: flex;
//     flex-direction: column;
//     transition:width .5s;
//     margin-left:auto;
//     width:100%;
//     // flex-grow: 1;
//   }
.layout-col-2 {
    transition: width .5s;
    margin-left: auto;
    width: 84%;
    display: flex;
    flex-direction: column;
    // flex-grow:1;
    //    background:$body-background;
}

.sidemenu-style {
    overflow-y: auto;
    overflow-x: hidden;
    //   background:$side-bg!important;
    color: #ffffff99 !important
}

.sidebar-top {
    //   height: 50px;
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 1111;

    background: $side-bg-secondary !important;

    .side-logo {
        height: auto;
        padding: 6px 0px;
        // background: $side-bg-secondary;
        background: #e3d690;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $font-color-light;
        font-weight: 600;
        letter-spacing: .5px;
        font-size: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
    }

    .side-profile-img {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            // border:5px solid white;
        }

    }

    .side-profile-greetings {
        color: #ffffff99;
    }

    .side-profile-details {
        font-size: 16px;
        color: $font-color-light;
        font-weight: 600;
    }
}

.ant-layout-sider {
    background: transparent !important;
}

aside {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;

    ul {
        margin: 10px 0px !important;
        background: transparent !important;

        .ant-menu-item-selected {
            background: #303c54 !important;
        }

        li {
            .ant-menu-title-content {
                color: $font-color-light !important;
                font-size: 16px;
            }

            .ant-menu-title-content:hover {
                color: $font-light-secondary !important;
            }

            a {
                color: $font-color-light !important;
                background: transparent !important;
                font-size: 16px;
            }

            svg {
                font-size: 22px !important;
                margin-right: 20px;
            }

            ul {
                background: transparent !important;
                color: $font-light-secondary !important;

                li:hover {
                    background: #303c54 !important;
                    // color:$font-light!important;
                }
            }

            li:hover {
                color: $font-light-secondary !important;
            }
        }

        li:hover {
            background: #303c54 !important;

            a {
                color: white !important;
            }
        }

        .ant-menu-submenu-arrow,
        .ant-menu-item-icon {
            color: white !important;
        }
    }

}

.content {
    display: flex;
    flex-direction: row;
    flex: 4;
    min-height: 84vh;
    background: $body-bg-color;
    padding: 20px 0px
}

//   .content > article {
//     flex: 4;
//     min-height: 84vh;
//   }

.master-layout {
    nav {
        position: sticky;
        top: 0;
        background: white;
        height: 8vh;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);
        z-index: 1000 !important;
    }
}

.partner-image-gallary {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    img {
        max-height: 120px;
        max-width: 120px;
    }
}



//   NAVBAR STYLE START

.side-collapse {
    cursor: pointer;
}

.dd-menu-title {

    // background: #f8f8f8;
    border-bottom: 1px solid #e1e6eb;
    padding: 9px 0px;

    p {
        font-size: 14px;
        font-weight: 500 !important;
        text-transform: uppercase;
    }

}

.dd-avatar {
    img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        object-fit: cover;
    }
}

.dd-content {
    white-space: normal;
    line-height: 18px;
}

.profile-icon-img {
    width: 2rem;
    height: 2rem;
    border-radius: 20%;
    cursor: pointer;
    object-fit: cover;
    object-position: 30% 30%;
}

.dd-profile-info {
    padding: 10px 0px 15px 0px;
    border-bottom: 1px solid #e1e6eb;

    .admin-dd-avatar {

        img {
            width: 45px;
            height: 45px;
            border-radius: 20%;
            cursor: pointer;
            object-fit: cover;
            object-position: 30% 30%;
        }
    }
}


//   NAVBAR STYLE END

/* Footer style end*/
.footer-style {
    background: #FFFFFF;
    height: 8vh;
    width: 100%;
    position: sticky;
    text-align: center;
    bottom: 0;
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0px;

    box-shadow: 0px -5px 10px rgba(0, 0, 0, .2);

    //  p{
    //       width:100%;
    //      //  margin:auto;
    //  //    margin-right:10px;
    //      font-weight: 500;
    //      font-size: 14px;
    //      text-align: center;
    //      margin-bottom: 0px;
    //  }
}



/* Footer style end*/


//  Theme colors

.font-orange {
    color: $theme-orange-color
}

.font-red {
    color: $theme-red-color
}

.font-black {
    color: $theme-dark-color
}

.bg-orange {
    color: $theme-orange-color
}

.bg-red {
    color: $theme-red-color
}

.bg-black {
    color: $theme-dark-color
}

.profile-card-img {
    text-align: center;
    padding: 5% 0%;

    img {
        max-width: 100px;
        height: auto;
        border-radius: 12px;
        object-fit: cover !important;
        object-position: 10% 20%;
        // background: black;

    }
}

.news-modal-img{
    img{
        max-height: 200px;
        height :200px;
        background: red;
    }
   
}

.input-grey-rounded {
    font-size: 16px;
    line-height: 1.5;
    // background: #E9EEF0;
    border: 1px solid #D5D9DB;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 15px 10px;
    background-size: 20px 20px;
    border-radius: 100px;
    width: 300px;
    height: 40px;
    padding: .2em 1em .5em 2.5em;
}

// .input-grey-rounded::placeholder {
//       color: #838D99;
// }

.input-grey-rounded:focus {
    outline: none;
    background-color: #FFF;
    border: 1px solid #84A2FA;
}
.blog-modal-img{
    img{
        max-width: 40%;
        max-height: auto;
    }
    
}
.sunEditor-react{
    table{
        border : 1px solid green;
    }
    table{
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dcdcdc;
      }
      
      /* Style the table header (th) */
      table th {
        background-color: #f5f5f5;
        border: 1px solid #dcdcdc;
        padding: 10px;
        text-align: left;
      }
      
      /* Style the table rows (td) */
      table td {
        border: 1px solid #dcdcdc;
        padding: 10px;
        text-align: left;
      }
}

.ant-menu-item .ant-menu-item-icon+span, .ant-menu-item .anticon+span, .ant-menu-submenu-title .ant-menu-item-icon+span, .ant-menu-submenu-title .anticon+span{
    margin-left: 0px!important;
}